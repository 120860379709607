<template>
  <el-dialog
    v-model="winShow"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('assign.create_application')"
    @close="closeWin">
		<!-- 表单 -->
		<el-form
      ref="form"
      :model="formData.form"
      :rules="formData.rules"
      label-position="right"
      label-width="170px">
			<el-form-item :label="t('assign.select_project')" prop="project_id">
				<el-select
          v-model="formData.form.project_id"
          @change="setProduct"
          value-key="id">
					<el-option :placeholder="t('form.selectPlaceholder')"
					v-for="(item, index) in projectLists" :label="item.name" :value="item" :key="index" />
				</el-select>
			</el-form-item>
      <!-- 申请数量 -->
			<el-form-item :label="t('assign.application_count')" prop="content">
				<el-input-number v-model="formData.form.content" :min="1"/>
			</el-form-item>
      <!-- 产品名称 -->
			<el-form-item :label="t('product.product_name')" prop="product_name">
				<el-input v-model="formData.form.product_name" disabled :placeholder="t('form.inputPlaceholder')" />
			</el-form-item>
      <!-- 注册码 -->
			<el-form-item :label="t('project.license')" prop="lic_key" v-if="hasLic">
				<el-input v-model="formData.form.lic_key" disabled :placeholder="t('form.inputPlaceholder')" :rows="6" type="textarea" />
			</el-form-item>
			<el-form-item :label="t('project.license')" prop="lic_key" v-else>
				<el-input v-model="formData.form.lic_key" :placeholder="t('form.inputPlaceholder')" :rows="6" type="textarea" />
			</el-form-item>
			<el-form-item :label="t('user.notes')">
				<el-input v-model="formData.form.comment" :placeholder="t('form.inputPlaceholder')" :rows="6" type="textarea" />
			</el-form-item>
		</el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="onSubmit" :loading="submitLoading" type="primary">{{ t('form.submit') }}</el-button>
        <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, inject, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { AuthManagement } from '@/utils/api'
import store from '@/store'
import { getProjectList } from '@/utils/common'

const t = inject('t')
const emit = defineEmits(['getList'])
const { proxy } = getCurrentInstance()
const winShow = ref(false)
const projectLists = ref([])
const product_info = ref({})
const form = ref()
const hasLic = ref(false)
const submitLoading = ref(false)
const formData = reactive({
	form: {
		content: 1
	},
	rules: {
		project_id: [{ required: true, message: t('form.emptyErrMsg') }],
		content: [{ required: true, message: t('form.emptyErrMsg') }],
		product_name: [{ required: true, message: t('form.emptyErrMsg') }],
		lic_key: [{ required: true, message: t('form.emptyErrMsg') }]
	}
})

// 设置产品信息
const setProduct = (data) => {
	product_info.value = data.product
	formData.form.product_name = product_info.value.name
	formData.form.lic_key = data.lic_key
	formData.form.failback_key = data.failback_key
	hasLic.value = !!data.lic_key
}

// 表单提交
const onSubmit = () => {
	form.value.validate(async valid => {
		if(!!!valid) return
    submitLoading.value = true
		const subData = {
		  ...formData.form,
			project_id: formData.form.project_id.id,
		  content: {
		    hosts: formData.form.content,
		  },
		  create_user_id: store.state.users.id,
		  enterprise_id: store.state.users.enterprise_id,
		  start_at: proxy.$startTime(),
		  product_id: product_info.value.id,
		  use_period: 100,
		  source: 1
		}
		delete subData.product_name
		// 新增
    try {
      await AuthManagement.ApplicationCreate(subData)
		  ElMessage.success(t('table.add_success'))
		  emit('getList')
      submitLoading.value = false
		  closeWin()
    } catch (error) {
      submitLoading.value = false
    }
	})
}

// 关闭框
const closeWin = () => {
  form.value.resetFields()
  formData.form = {
    content: 1
  }
  winShow.value = false
	hasLic.value = false
}

// 打开弹框
const openWin = async () => {
  winShow.value = true;
	projectLists.value = await getProjectList()
}

// 查询
defineExpose({
  openWin: openWin
})
</script>