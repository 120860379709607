<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getApplicationList" @onCreate="onCreate" @onExportExcel="onExportExcel" />
    <custem-table
      :tableInfo="tableInfo"
      :tableLoading="tableLoading"
      @getList="getApplicationList"></custem-table>
    <!-- 详情 -->
    <DetailWin ref="detail_win" @closeApplication="closeApplication" @getList="getApplicationList" />
    <!-- 表单 -->
    <Form ref="form" @getList="getApplicationList" />
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import { ref, getCurrentInstance, inject, onMounted, reactive, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from "element-plus"
import DetailWin from './components/Detail'
import Form from './components/Form'
import store from '@/store'
import { AuthManagement } from '@/utils/api'
import { subApplicaitonTable, scanApplicaitonTable } from './data.js'

const tableLoading = ref(false)
const t = inject('t')
const detail_win = ref()
const { proxy } = getCurrentInstance()
const form = ref()
// 创建
const onCreate = () => {
  if (!!!form.value) return
  form.value.openWin()
}

// 改变申请状态的请求
const changeState = (id, data) => {
  return new Promise((resolve, reject) => {
    AuthManagement.ApplicationUpdate(id, data).then(res => {
      resolve(1)
    })
  })
}

// 撤销申请
const closeApplication = (data) => {
  let status = 8, statusName = t('form.revoke')
  if (!!!proxy.$userRole(['Subordinate'])) {
    status = 9;
    statusName = t('form.reject')
  }
  ElMessageBox.prompt(t('user.notes'), `${statusName} ${t('assign.apply')}`, {
    confirmButtonText: t('form.submit'),
    cancelButtonText: t('form.cancel'),
    inputPlaceholder: t('user.notes')
  })
    .then(async ({ value }) => {
      const res = await changeState(data.id, {
        status: status,
        comment: value
      })
      if (res) {
        ElMessage.success(`${statusName} ${t('assign.success')}`)
        getApplicationList()
      }
    }).catch()
}

// 获取子用户申请列表
const getApplicationList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.ApplicationList({ ...search })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 分配
const confirmAss = (data) => {
  openDetail({
    ...data,
    openType: 'confirm'
  })
}

// 详情
const openDetail = (data) => {
  if (!!!detail_win.value) return
  detail_win.value.openWin(data)
}

const tableInfo = reactive(store.state.users.tags === 1 ? scanApplicaitonTable(proxy, confirmAss, openDetail) : subApplicaitonTable(proxy, confirmAss, closeApplication, openDetail))

// 导出
const onExportExcel = () => {
  window.open(`${process.env.VUE_APP_API_URI}/v1/allocation_assignment/csv?type=allocation&api_key=${store.state.authorization}`)
}

onMounted(() => {
  getApplicationList()
})
</script>