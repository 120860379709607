<template>
	<div v-if="$userRole(['Subordinate'])" class="list-tool-bar">
		<el-button-group class="action">
			<el-button @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
			<el-button @click="emit('onExportExcel')" type="primary" plain>{{ t('table.export') }}</el-button>
		</el-button-group>
	</div>
</template>
<script setup>
  import { inject } from 'vue'
  const t = inject('t')
	const emit = defineEmits(['onCreate', 'onExportExcel'])
</script>
